import React, { useEffect } from 'react'
import { graphql } from 'gatsby'
import { Seo } from '../components/base'
import { BlockContent, SocialSharing } from '../components/shared'
import { getPageBuilderComponents } from '../components/base/PageBuilderComponents'
import { SinglePage } from './SinglePage'
import { getLocaleMarket } from '../utils/getLocaleMarket'

const LandingPage = ({ data, location, pageContext }) => {
  // eslint-disable-next-line no-prototype-builtins
  if (data?.sanityLandingPage) {
    const {
      pageBuilder,
      image,
      icon,
      titleIcon,
      title,
      subtitle,
      desc,
      usedWithNewBioDivider,
      fullWidthHeader,
      customScripts,
      hideSocial,
      _rawIntroduction
    } = data.sanityLandingPage
    const languages = { pageContext }
    let myMarket = getLocaleMarket()

    // customScripts is a hidden sanity field, currently set only for the CookieBot Table integration for the /ueber-uns/datenschutz page
    useEffect(() => {
      if (typeof document !== `undefined`) {
        customScripts.forEach(element => {
          const script = document.createElement('script')

          script.src = element.scriptUrl
          script.async = !!element.async
          script.id = element.scriptId

          const scriptAnchor = document.querySelector('#customScript')
          scriptAnchor.appendChild(script)
          return () => scriptAnchor.removeChild(script)
        })
      }
    }, [customScripts])

    return (
      <SinglePage
        title={title}
        subtitle={subtitle}
        heroImage={image}
        usedWithNewBioDivider={usedWithNewBioDivider}
        fullWidthHeader={fullWidthHeader}
        translations={languages}
        icon={icon}
        titleIcon={titleIcon}
        hasSocialSharing={!hideSocial}>
        <Seo title={title} description={desc} />
        <section className="md:mx-auto singlePageSection">
          <div
            className={`mb-20 mt-10 ${
              usedWithNewBioDivider && 'usedWithNewBioDivider'
            }`}>
            {image && (
              <>
                <h1 className="text-center mt-8 md:mt-0">{title}</h1>
                {subtitle != ' ' && (
                  <h2 className="text-center bioSubtitle">{subtitle}</h2>
                )}
              </>
            )}

            {_rawIntroduction && (
              <BlockContent
                data={_rawIntroduction}
                className=" md:w-2/3 md:mx-auto  last:pb-0"
              />
            )}
            {pageBuilder.map((item, index) => {
              return (
                <React.Fragment key={index}>
                  {getPageBuilderComponents(
                    item,
                    myMarket,
                    false,
                    data,
                    pageContext
                  )}
                  {customScripts?.length > 0 && (
                    <div
                      id="customScript"
                      className={`les md:w-2/3 md:mx-auto last:pb-0
                      }`}></div>
                  )}
                </React.Fragment>
              )
            })}
          </div>
          {!hideSocial && <SocialSharing link={location} />}
        </section>
      </SinglePage>
    )
  } else return null
}

export default LandingPage

export const page = graphql`
  query ($title: String!, $language: String!) {
    sanityLandingPage(
      title: { eq: $title }
      i18n_lang: { eq: $language }
      isNotAvailableInCountry: { ne: true }
    ) {
      hideSocial
      subtitle
      title
      desc
      _rawIntroduction
      usedWithNewBioDivider
      fullWidthHeader
      publishFrom
      publishUntil
      image {
        ...ImageObjectQuery
      }
      icon {
        ...ImageObjectQuery
      }
      titleIcon {
        ...ImageObjectQuery
      }
      customScripts {
        async
        scriptId
        scriptUrl
      }
      ...LandingPageBuilderElements
    }
  }
`
